/**
 * require.context(directory,useSubdirectories,regExp)
  directory:表示检索的目录
  useSubdirectories：表示是否检索子文件夹
  regExp:匹配文件的正则表达式,一般是文件名
 */
//读取components下后缀为.vue的文件
const files = require.context('@/components/', true, /\.vue$/);
let names = {}
// 遍历输出匹配结果

files.keys().forEach(key => {
    //files(key).default 获取所有vue文件的生命周期和方法
    const component = files(key).default;
    component.install = function (Vue) {
      //如果.vue文件下有对应的名称，则注册组件
        if (component.name) {
            Vue.component(component.name, component)
        }
    }
    names[component.name] = component

})
export default names