import names from "@/components/export.js"


const install = function (Vue) {
    for (const key in names) {
        Vue.use(names[key])
    }
}
export default {
    install
}