import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push (location) {
//     return originalPush.call(this, location).catch(err => err)
// }

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import("../views/AboutUs.vue")
    },
    {
        path: '/service-support',
        name: 'service-support',
        component: () => import("../views/ServiceSupport.vue")
    },
    {
        path: '/valueAdded-service',
        name: 'valueAdded-service',
        component: () => import("../views/valueAddedService.vue")
    },
    {
        path: '/main_business',
        name: 'main_business',
        component: () => import("../views/MainBusiness.vue")
    },
    {
        path: '/autonomous_system',
        name: 'autonomous_system',
        component: () => import("../views/AutonomousSystem.vue")
    },
    {
        path: '/join_us',
        name: 'join_us',
        component: () => import("../views/JoinUs.vue")
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
