<template>
  <div>
    <div class="waybill_query_item">
      <div class="waybill_query_leftbg"></div>
      <div class="waybill_query">{{ title }}</div>
      <div class="waybill_query_rightbg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "theme",
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.waybill_query_item {
  display: flex;
  justify-content: center;
  .waybill_query_leftbg {
    width: 27px;
    height: 12px;
    background-image: linear-gradient(
      to left,
      rgba(147, 174, 218, 0) 0%,
      rgba(24, 45, 93, 0.54) 100%
    );
    transform: translateY(10px);
  }
  .waybill_query_rightbg {
    width: 27px;
    height: 12px;
    background-image: linear-gradient(
      to right,
      rgba(147, 174, 218, 0) 0%,
      rgba(24, 45, 93, 0.54) 100%
    );
    transform: translateY(10px);
  }
  .waybill_query {
    font-size: 24px;
    margin: 0 14px 0 14px;
  }
}
</style>