<template>
  <div>
    <div class="writtenWords_bg">
      <img width="100%" :src="img" alt="" />
      <div class="writtenWords_item">
        <div class="service_support">{{ title }}</div>
        <div class="service_support_underline"></div>
        <ul class="writtenWords">
          <li
            :style="{ marginRight: item.marginRight }"
            v-for="(item, index) in writtenWordsList"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'textCommon',
  props: {
    title: {
      type: String
    },
    img: {
      type: String
    }

  },
  data () {
    return {
      writtenWordsList: [
        {
          title: "诚信",
          marginRight: '31px',
        }, {
          title: "安全",
          marginRight: '31px',
        }, {
          title: "高效",
          marginRight: '31px',
        }, {
          title: "快捷",
        },
      ]
    }
  }


}
</script>

<style lang="less" scoped>
.writtenWords_bg {
  //   height: 421px;
  position: relative;
  text-align: left;
  color: #fff;
  .writtenWords_item {
    position: absolute;
    left: 50%;
    top: 50%;
    // top: 142px;
    width: 210px;
    margin-left: -105px;
    margin-top: -38px;

    .writtenWords {
      display: flex;
      font-size: 14px;
      opacity: 0.85;
      margin-top: 7px;
    }
    .service_support {
      font-size: 30px;
      border-bottom: 1px solid #fff;
      margin-bottom: 2px;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
    .service_support_underline {
      width: 124px;
      height: 1px;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>